import { Component, Inject, OnInit } from '@angular/core';
import {
    TableDescriptor,
    ToolbarButton,
} from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { LocalStorageService } from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../services/message.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    FunctionTableColumn,
    IfElseTableColumn,
    NumberTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {
    Condition,
    Operator,
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import { InvoiceGenerationDialogComponent } from '../../dialogs/invoice-generation-dialog/invoice-generation-dialog.component';
import { RechnungGeneration } from './rechnung-generation';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Invoice } from './rechnung';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RechnungStornoDialogComponent } from './rechnung-storno-dialog/rechnung-storno-dialog.component';
import { RechnungBelegeDialogComponent } from './rechnung-belege-dialog/rechnung-belege-dialog.component';
import { AppService } from '../../app.service';
import { RechnungEmailDialogComponent } from './rechnung-email-dialog/rechnung-email-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { MonthSelectorComponent } from '../../components/month-selector/month-selector/month-selector.component';
import { MonthSelectorResult } from '../../components/month-selector/month-selector/month-selector';
import { KieneFileService } from '../../../../projects/kiene-core/src/lib/services/files/kiene-file.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { SelectDateDialogComponent } from '../../dialogs/select-date-dialog/select-date-dialog.component';

@Component({
    selector: 'kiene-rechnungen',
    templateUrl: './rechnungen.component.html',
    styleUrls: ['./rechnungen.component.scss'],
})
export class RechnungenComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    vetvetMandant: boolean;

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private appService: AppService,
        private fileService: KieneFileService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.localStorageService.setCurrentClientVisible(true);
    }

    ngOnInit() {
        this.vetvetMandant = !!(
            this.localStorageService.getCurrentMandant()?.mandant_id === 0
        );

        this.tableDescriptor.headline = 'Rechnungen';
        this.tableDescriptor.uniqueIdentifier = 'Rechnungen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'rechnung/';
        this.tableDescriptor.route = '/finanzen/rechnungen/';
        this.tableDescriptor.nameOfIdField = 'rechnung_id';
        this.tableDescriptor.searchPlaceholder = 'Rechnungen durchsuchen';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.columns.push(new TableColumn('rechnung_id', 'ID'));
        this.tableDescriptor.columns.push(
            new TableColumn('rechnung_nr', 'Rechnung Nr.')
        );
        if (this.vetvetMandant) {
            this.tableDescriptor.columns.push(
                new TableColumn('benutzer', 'Benutzer')
            );
        } else {
            this.tableDescriptor.columns.push(
                new TableColumn('kunde', 'Kunde')
            );
            this.tableDescriptor.columns.push(
                new TableColumn('zusatz', 'Zusatz')
            );
            this.tableDescriptor.columns.push(
                new TableColumn('gruppe', 'Gruppe')
            );
        }
        this.tableDescriptor.columns.push(
            new TableColumn('rechnungsdatum', 'Rechnungsdatum', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        if (this.vetvetMandant) {
            this.tableDescriptor.columns.push(
                new TableColumn('status_bezahlung', 'Status Bezahlung')
            );
            this.tableDescriptor.columns.push(
                new TableColumn('payment_intent_id', 'Stripe Id')
            );
        }
        this.tableDescriptor.columns.push(
            new NumberTableColumn('gesamt_netto', 'Gesamt Netto (€)')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('gesamt_brutto', 'Gesamt Brutto (€)')
        );

        if (!this.vetvetMandant) {
            const conditionSettingsPrimanota = [
                new ConditionSetting(
                    [new Condition('primanota_id', Operator.EXISTIERT, null)],
                    {
                        icon: 'check',
                        color: 'green',
                        tooltip: 'Bereits zur FiBu exportiert',
                    }
                ),
            ];
            this.tableDescriptor.columns.push(
                new ConditionTableColumn(
                    'primanota_id',
                    'Primanota',
                    conditionSettingsPrimanota,
                    {
                        sortFields: ['primanota_id'],
                    }
                )
            );

            const conditionSettingsGutschrift = [
                new ConditionSetting(
                    [
                        new Condition(
                            'kundengutschrift_id',
                            Operator.EXISTIERT,
                            null
                        ),
                    ],
                    {
                        icon: 'check',
                        color: 'green',
                        tooltip: 'Gutschrift erstellt',
                    }
                ),
            ];
            this.tableDescriptor.columns.push(
                new ConditionTableColumn(
                    'kundengutschrift_id',
                    'Gutschrift',
                    conditionSettingsGutschrift,
                    { sortFields: ['kundengutschrift_id'] }
                )
            );
        }

        const conditionSettingsMail = [
            new ConditionSetting([new Condition('mail', Operator.GLEICH, 0)], {
                icon: 'mail',
                color: 'orange',
                tooltip: 'Email in Warteschlange',
            }),
            new ConditionSetting(
                [new Condition('mail', Operator.GROESSER, 0)],
                {
                    icon: 'mark_email_read',
                    color: 'green',
                    tooltip: 'Email gesendet',
                }
            ),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn('mail', 'Email', conditionSettingsMail, {
                sortFields: ['mail'],
            })
        );

        this.tableDescriptor.columns.push(
            new TableColumn('mail_zeitstempel', 'Email Datum', 'date')
        );

        const conditionSettingsDrucker = [
            new ConditionSetting(
                [new Condition('gedruckt', Operator.GROESSER, 0)],
                {
                    icon: 'printer',
                    color: 'green',
                    tooltip: 'PDF gedruckt',
                }
            ),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn(
                'gedruckt',
                'Gedruckt',
                conditionSettingsDrucker,
                {
                    sortFields: ['gedruckt'],
                }
            )
        );

        this.tableDescriptor.columns.push(
            new TableColumn('gedruckt_zeitstempel', 'Gedruckt Datum', 'date')
        );

        this.tableDescriptor.columns.push(
            new TableColumn('pdf_id', 'PDF', 'pdf')
        );

        this.tableDescriptor.options.push(
            new TableOption(
                'einzelnfreigeben',
                'Rechnung freigeben',
                'verified',
                2973,
                {
                    conditions: [
                        new Condition('status_id', Operator.IN, [51, 52]),
                    ],
                }
            )
        );
        if (!this.vetvetMandant) {
            this.tableDescriptor.options.push(
                new TableOption('delete', 'Rechnung löschen', 'delete', 2974, {
                    conditions: [
                        new Condition('status_id', Operator.GLEICH, 51),
                    ],
                })
            );
            this.tableDescriptor.options.push(
                new TableOption(
                    'stornokorrektur',
                    'Stornierte Rechnung korrigieren',
                    'bug_report',
                    2963,
                    {
                        conditions: [
                            new Condition('status_id', Operator.GLEICH, 55),
                            new Condition(
                                'rechnung_id_neu',
                                Operator.EXISTIERT_NICHT,
                                null
                            ),
                        ],
                    }
                )
            );
            this.tableDescriptor.options.push(
                new TableOption(
                    'storno',
                    'Rechnung stornieren',
                    'clear',
                    2963,
                    {
                        conditions: [
                            new Condition(
                                'status_id',
                                Operator.GROESSER_GLEICH,
                                53
                            ),
                            new Condition(
                                'status_id',
                                Operator.KLEINER_GLEICH,
                                54
                            ),
                        ],
                    }
                )
            );
            this.tableDescriptor.options.push(
                new TableOption(
                    'gutschrift_anzeigen',
                    'Gutschrift anzeigen',
                    'description',
                    2945,
                    {
                        conditions: [
                            new Condition(
                                'kundengutschrift_id',
                                Operator.EXISTIERT,
                                ''
                            ),
                        ],
                    }
                )
            );
            this.tableDescriptor.options.push(
                new TableOption(
                    'rechnung_neu_anzeigen',
                    'Neue Rechnung anzeigen',
                    'monetization_on',
                    2971,
                    {
                        conditions: [
                            new Condition(
                                'rechnung_id_neu',
                                Operator.EXISTIERT,
                                null
                            ),
                        ],
                    }
                )
            );
        }
        this.tableDescriptor.options.push(
            new TableOption('drucken', 'Rechnung drucken', 'printer', 2962, {
                conditions: [new Condition('status_id', Operator.GLEICH, 53)],
            })
        );

        this.tableDescriptor.expandable = true;
        this.tableDescriptor.expandableFieldName = 'positionen';
        this.tableDescriptor.expandableColumns.push(
            new DateTableColumn('datum', 'Belegdatum', 'dd.MM.yyyy')
        );
        this.tableDescriptor.expandableColumns.push(
            new IfElseTableColumn(
                'beleg',
                'Beleg',
                'abgabebeleg_id',
                'abgabebeleg_nr',
                'tagebuch_nr'
            )
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikel', 'Artikel')
        );
        this.tableDescriptor.expandableColumns.push(
            new FunctionTableColumn('behandlung', 'Typ', this.getArticleType)
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikelmenge', 'Menge')
        );
        this.tableDescriptor.expandableColumns.push(
            new NumberTableColumn('einzelpreis', 'Einzelpreis (€)', '1.2-2')
        );
        this.tableDescriptor.expandableColumns.push(
            new NumberTableColumn(
                'gesamtpreis',
                'Gesamtpreis Netto (€)',
                '1.2-2'
            )
        );
        this.tableDescriptor.expandableColumns.push(
            new NumberTableColumn('mwstsatz', 'MwSt (%)', '1.0-0')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('kommentar', 'Kommentar')
        );
        if (this.vetvetMandant) {
            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton(
                    'generate_vetvet',
                    'monetization_on',
                    2968,
                    'Rechnungen generieren'
                )
            );
        } else {
            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton(
                    'generate_praxis',
                    'monetization_on',
                    2979,
                    'Rechnungen generieren'
                )
            );
        }
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'allefreigeben',
                'verified',
                2961,
                'Alle Rechnungen freigeben'
            )
        );
        if (!this.vetvetMandant) {
            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton(
                    'exportieren',
                    'picture_as_pdf',
                    2962,
                    'Alle Rechnungen exportieren'
                )
            );
        }
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'mailssenden',
                'mail',
                2964,
                'Alle Rechnungen per Mail an Kunden senden'
            )
        );
        if (!this.vetvetMandant) {
            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton(
                    'primanota',
                    'account_balance',
                    2978,
                    'FiBu CSV generieren'
                )
            );

            const mandant = this.localStorageService.getCurrentMandant();
            if (mandant.mandant_id == 1) {
                this.tableDescriptor.toolbarButtons.push(
                    new ToolbarButton(
                        'brueninghoff',
                        'pets',
                        2969,
                        'Brüninghoff CSV erstellen'
                    )
                );
            }
        } else {
            this.tableDescriptor.toolbarButtons.push(new ToolbarButton('stripe', 'send', 2966, 'Rechnungen an Stripe senden'));
        }

        this.tableDescriptor.permissionCreate = 2973;
        this.tableDescriptor.permissionDelete = 2974;
        this.tableDescriptor.permissionUpdate = 2973;
        this.tableDescriptor.permissionView = 2971;

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Rechnung erstellt',
                51,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Rechnung freigegeben',
                52,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Rechnung PDF erzeugt',
                53,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Rechnung exportiert',
                54,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Rechnung storniert',
                55,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );

        this.tableDescriptor.initialSortColumn = 'rechnung_id';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    public getArticleType(behandlung: number): string {
        return behandlung === 1 ? 'Behandlung' : 'Abgabe';
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    openPdf(invoice: Invoice) {
        const input = new PdfViewerDialogInput(
            'rechnung/read_or_mail_pdf.php',
            new HttpParams().set(
                'rechnung_id',
                invoice?.rechnung_id?.toString()
            ),
            'Rechnung ' + invoice?.rechnung_nr,
            { email: { sende_mail: true, kunde_id: invoice?.kunde_id } }
        );
        input.isInvoice = true;
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const invoice: Invoice = event.value;
        if (event.name === 'details') {
            if (this.hasPermission(2973)) {
                this.router.navigate(
                    [invoice[this.tableDescriptor.nameOfIdField]],
                    { relativeTo: this.route }
                );
            } else {
                this.openPdf(invoice);
            }
        } else if (event.name === 'generate_praxis') {
            this.openInvoiceGenerationDialog();
        } else if (event.name === 'generate_vetvet') {
            this.generateInvoicesVetvet();
        } else if (event.name === 'stripe') {
            this.sendToStripe();
        } else if (event.name === 'primanota') {
            this.generatePrimanota();
        } else if (event.name === 'allefreigeben') {
            this.alleFreigeben();
        } else if (event.name === 'exportieren') {
            this.alleExportieren();
        } else if (event.name === 'mailssenden') {
            this.mailsSenden();
        } else if (event.name === 'drucken') {
            this.drucken(invoice);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(invoice);
        } else if (event.name === 'showPdf') {
            this.openPdf(invoice);
        } else if (event.name === 'storno') {
            this.openStornoDialog(invoice);
        } else if (event.name === 'einzelnfreigeben') {
            this.einzelnFreigeben(invoice);
        } else if (event.name === 'stornokorrektur') {
            this.openRechnungBelegeDialog(invoice);
        } else if (event.name === 'gutschrift_anzeigen') {
            const input = new PdfViewerDialogInput(
                'kundengutschrift/read_or_mail_pdf.php',
                new HttpParams().set(
                    'kundengutschrift_id',
                    invoice?.kundengutschrift_id?.toString()
                ),
                'Gutschrift ' + invoice?.kundengutschrift_id?.toString(),
                {
                    email: {
                        sende_mail: true,
                        kunde_id: invoice?.kunde_id,
                    },
                }
            );
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'rechnung_neu_anzeigen') {
            this.rechnungNeuAnzeigen(invoice);
        } else if (event.name === 'brueninghoff') {
            this.downloadBrüninghoff();
        }
    }

    downloadBrüninghoff() {
        const dialog = this.dialog.open(MonthSelectorComponent, {
            width: '40%',
            minWidth: '400px',
        });

        dialog.afterClosed().subscribe((response: MonthSelectorResult) => {
            if (response?.confirmed) {
                const params = new HttpParams()
                    .set('monat', response?.monat?.toString())
                    .set('jahr', response?.jahr?.toString());
                this.fileService.downloadCSV(
                    this.apiBaseUrl + 'rechnung/csv_brueninghoff.php',
                    params
                );
            }
        });
    }

    rechnungNeuAnzeigen(invoice: Invoice) {
        this.router.navigate([invoice.rechnung_id_neu], {
            relativeTo: this.route,
        });
    }

    openStornoDialog(invoice: Invoice) {
        const dialogRef = this.dialog.open(RechnungStornoDialogComponent, {
            data: {
                invoice: invoice,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.api
                    .updateGeneric(
                        this.apiBaseUrl + 'rechnung/stornieren.php',
                        result
                    )
                    .subscribe(
                        (response) => {
                            this.messageService.infoMessage(
                                'Rechnung wurde storniert!'
                            );
                            this.api.reload();
                            this.openRechnungBelegeDialog(invoice);
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Rechnung konnte nicht storniert werden!',
                                error
                            );
                            this.api.reload();
                        }
                    );
            }
        });
    }

    openRechnungBelegeDialog(invoice: Invoice) {
        const dialogRef = this.dialog.open(RechnungBelegeDialogComponent, {
            width: '80%',
            data: {
                invoice: invoice,
            },
        });
    }

    openDeleteDialog(invoice: Invoice) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Rechnung wirklich löschen?',
                text: 'Rechnung wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(
                        this.apiBaseUrl + 'rechnung/delete.php',
                        invoice
                    )
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Rechnung wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Rechnung konnte nicht gelöscht werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    sendToStripe() {
        const input = new ConfirmDialogInput();
        input.headline = 'Rechnungen an Stripe';
        input.text = 'Sollen alle freigegeben Rechnungen an Stripe gesendet werden?';
        this.dialog.open(ConfirmDialogComponent, { data: input }).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api.post(this.apiBaseUrl + 'stripe/send.php', {}).subscribe({
                        next: () => {
                            this.api.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });
    }

    generateInvoicesVetvet() {
        this.dialog.open(SelectDateDialogComponent, {
            data: {
                headline: 'Rechnungen VetVet',
                text: 'Sollen die Rechnungen erzeugt werden?',
                label: 'Zeitraum bis',
                okButton: 'Rechnungen erzeugen',
            },
        }).afterClosed().subscribe({
            next: (result: Date) => {
                if (result) {
                    const dat = {
                        datum_bis: result
                    }
                    this.api.post(this.apiBaseUrl + 'rechnung/generieren_vetvet.php', dat).subscribe({
                        next: () => {
                            this.api.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });
    }

    openInvoiceGenerationDialog() {
        const dialogRef = this.dialog.open(InvoiceGenerationDialogComponent, {
            minWidth: '400px',
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                const rg = <RechnungGeneration>data;
                this.api
                    .post(this.apiBaseUrl + 'rechnung/generieren.php', rg)
                    .subscribe(
                        (response) => {
                            this.messageService.infoMessage(
                                'Die Rechnungen wurden erfolgreich generiert!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Rechnungen konnten nicht generiert werden. Es ist ein Fehler aufgetreten: ',
                                error
                            );
                        }
                    );
            }
        });
    }

    generatePrimanota() {
        this.http
            .post(this.apiBaseUrl + 'primanota/generieren_rechnungen.php', {})
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Die Primanota wurde erfolgreich generiert!'
                    );
                    this.router.navigate(['../primanota'], {
                        relativeTo: this.route,
                    });
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Es ist ein Fehler beim Generieren der Primanota aufgetreten!',
                        error
                    );
                }
            );
    }

    alleFreigeben() {
        this.appService.setDataLoading(true);
        this.messageService.infoMessage(
            'Rechnungen werden freigegeben, bitte warten!'
        );
        this.api
            .post(this.apiBaseUrl + 'rechnung/freigeben_alle.php', null, null)
            .subscribe(
                (response) => {
                    this.appService.setDataLoading(false);
                    this.messageService.infoMessage(
                        'Rechnungen erfolgreich freigegeben!'
                    );
                    this.api.reload();
                },
                (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Es ist ein Fehler aufgetreten!',
                        error
                    );
                }
            );
    }

    einzelnFreigeben(invoice: Invoice) {
        this.appService.setDataLoading(true);
        this.messageService.infoMessage(
            'Rechnung wird freigegeben, bitte warten!'
        );
        this.api
            .post(
                this.apiBaseUrl + 'rechnung/freigeben_einzeln.php',
                invoice,
                null
            )
            .subscribe(
                (response) => {
                    this.appService.setDataLoading(false);
                    this.messageService.infoMessage(
                        'Rechnung erfolgreich freigegeben!'
                    );
                    this.api.reload();
                },
                (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Rechnung konnte nicht freigegeben werden!',
                        error
                    );
                }
            );
    }

    drucken(r: Invoice) {
        this.appService.setDataLoading(true);
        this.http
            .get(this.apiBaseUrl + 'rechnung/exportieren.php', {
                params: new HttpParams()
                    .set('rechnung_id', r.rechnung_id.toString())
                    .set('returnpdf', '0'),
            })
            .subscribe({
                next: (response) => {
                    this.appService.setDataLoading(false);
                    this.api.reload();
                    this.openPdf(r);
                },
                error: (err) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    mailsSenden() {
        const dialogRef = this.dialog.open(RechnungEmailDialogComponent);

        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.messageService.infoMessage(
                        'Rechnungen werden im Hintergrund gesendet!'
                    );

                    this.api
                        .post(
                            this.apiBaseUrl + 'rechnung/send_mails.php',
                            result,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Alle Rechnungen per Mail gesendet!'
                                );
                                this.api.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Es ist ein Fehler beim Versenden der Rechnungen aufgetreten!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    alleExportieren() {
        this.appService.setDataLoading(true);
        this.messageService.infoMessage(
            'Rechnungen werden exportiert, bitte warten!'
        );
        // TODO: Download Service nutzen
        this.http
            .get(this.apiBaseUrl + 'rechnung/exportieren.php', {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe(
                (response) => {
                    if (response) {
                        const pdfBlob = new Blob([response.body], {
                            type: 'application/pdf',
                        });
                        if (pdfBlob.size > 0) {
                            const url = window.URL.createObjectURL(pdfBlob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            document.body.appendChild(a);
                            a.href = url;
                            a.download = 'Rechnungen.pdf';
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        this.api.reload();
                    }
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Fehler beim Exportieren der Rechnungen!',
                        error
                    );
                }
            );
    }
}
