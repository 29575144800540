type TableOptionCallback = (value: any) => void;
export class TableOption {
  name: string;
  title: string;
  icon: string;
  permissionId: number;
  conditions: Condition[];
  void: TableOptionCallback;

  constructor(
    name: string,
    title: string,
    icon: string,
    permissionId: number,
    options?: { conditions?: Condition[], void?: TableOptionCallback }
  ) {
    this.name = name;
    this.title = title;
    this.icon = icon;
    this.permissionId = permissionId;
    if (options?.conditions) {
      this.conditions = options?.conditions;
    }
    if (options?.void) {
      this.void = options?.void;
    }
  }

  checkConditions(row: any) {
    if (!this.conditions || this.conditions.length === 0) {
      return true;
    } else {
      let result = true;
      for (let c of this.conditions) {
        result = result && c.check(row);
      }
      return result;
    }
  }
}

export class TableOptionEvent {
  name: string;
  value: any;
  selectionToolbarButton = false;
  values: any[] = [];

  constructor(name?: string, value?: any, selectionToolbarButton?: boolean, values?: any[]) {
    this.name = name;
    this.value = value;
    this.selectionToolbarButton = selectionToolbarButton;
    this.values = values;
  }
}

export enum Operator {
  GLEICH,
  UNGLEICH,
  GROESSER,
  GROESSER_GLEICH,
  KLEINER,
  KLEINER_GLEICH,
  IN,
  EXISTIERT,
  EXISTIERT_NICHT,
  LAENGE_KLEINER,
  LAENGE_GROESSER
}

export class Condition {
  fieldName: string;
  operator: Operator;
  fieldValue: any;

  constructor(fieldName: string, operator: Operator, fieldValue: any) {
    this.fieldName = fieldName;
    this.operator = operator;
    this.fieldValue = fieldValue;
  }

  check(row: any): boolean {
    if (!row) {
      return;
    }
    let value = row[this.fieldName];

    if (this.operator === Operator.GLEICH) {
      return value == this.fieldValue;
    }

    if (this.operator === Operator.UNGLEICH) {
      return value != this.fieldValue;
    }

    if (this.operator === Operator.GROESSER) {
      return value > this.fieldValue;
    }

    if (this.operator === Operator.GROESSER_GLEICH) {
      return value >= this.fieldValue;
    }

    if (this.operator === Operator.KLEINER) {
      return value < this.fieldValue;
    }

    if (this.operator === Operator.KLEINER_GLEICH) {
      return value <= this.fieldValue;
    }

    if (this.operator === Operator.EXISTIERT) {
      if (value) {
        return true;
      }
    }

    if (this.operator === Operator.EXISTIERT_NICHT) {
      if (!value) {
        return true;
      }
    }

    if (this.operator === Operator.IN) {
      for (let v of this.fieldValue) {
        if (value === v) {
          return true;
        }
      }
    }

    if (this.operator === Operator.LAENGE_GROESSER) {
      if (value.length > this.fieldValue) {
        return true;
      }
    }

    if (this.operator === Operator.LAENGE_KLEINER) {
      if (value.length < this.fieldValue) {
        return true;
      }
    }

    return false;
  }
}
