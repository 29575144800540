import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BetriebsartenComponent} from '../administration/betriebsarten/betriebsarten.component';
import {BetriebstypenComponent} from '../administration/betriebstypen/betriebstypen.component';
import {
    CompanyGroupDetailComponent
} from '../administration/company-group/company-group-detail/company-group-detail.component';
import {CompanyGroupComponent} from '../administration/company-group/company-group.component';
import {LandkreiseComponent} from '../administration/landkreise/landkreise.component';
import {TierartenComponent} from '../administration/tierarten/tierarten.component';
import {AuthGuard} from '../app.auth.guard';
import {PreisgruppeArtikelComponent} from '../finanzen/preisgruppen/preisgruppe-artikel/preisgruppe-artikel.component';
import {PreisgruppenComponent} from '../finanzen/preisgruppen/preisgruppen.component';
import {RassenComponent} from "./rassen/rassen.component";
import {StammdatenComponent} from './stammdaten.component';
import {ViehhaendlerComponent} from './viehhaendler/viehhaendler.component';
import {EntsorgerComponent} from './entsorger/entsorger.component';

const routes: Routes = [
    {
        path: '',
        component: StammdatenComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'betriebe',
                loadChildren: () => import('./betriebe/betriebe.module').then(m => m.BetriebeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'unternehmensgruppen',
                component: CompanyGroupComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'unternehmensgruppen/:id',
                component: CompanyGroupDetailComponent,
                canActivate: [AuthGuard],
            },

            {
                path: 'betriebsarten',
                component: BetriebsartenComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'betriebstypen',
                component: BetriebstypenComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'tierarten',
                component: TierartenComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'rassen',
                component: RassenComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'landkreise',
                component: LandkreiseComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'preisgruppen',
                component: PreisgruppenComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'preisgruppen/:id',
                component: PreisgruppeArtikelComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'viehhaendler',
                component: ViehhaendlerComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'entsorger',
                component: EntsorgerComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class StammdatenRoutingModule {
}
