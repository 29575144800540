import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap,} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {
    MatAutocompleteActivatedEvent,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import {MessageService} from "../../services/message.service";
import {MatInput} from "@angular/material/input";
import {API_BASE_URL_SERVICE} from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";

@Component({
    selector: "kiene-autocomplete",
    templateUrl: "./kiene-autocomplete.component.html",
    styleUrls: ["./kiene-autocomplete.component.scss"],
})
export class KieneAutocompleteComponent
    implements OnInit, OnChanges, AfterViewInit {
    @ViewChild("inputAutocomplete") inputAutocomplete: MatInput;
    @ViewChild("trigger") trigger: MatAutocompleteTrigger;

    formControl = new FormControl(undefined);

    @Input("kieneObject") kieneObject: any;

    @Input("displayField") displayField = "bezeichnung";

    @Input("displayFields") displayFields: string[] = [];

    @Input("mapperDisplayFields") mapperDisplayFields: string[] = [];

    @Input("separator") separator = " ";

    @Input("apiUrl") apiUrl: string;

    @Input("genericApiUrl") genericApiUrl: string;

    @Input("params") params: HttpParams;

    @Input("placeholder") placeholder = "Bezeichnung";

    @Input("panelWidth") panelWidth: string;

    @Input("focus") focus = true;

    @Input("presetSelected") presetSelected = true;

    @Input("disabled") disabled = false;

    @Input("required") required = false;

    @Input("clearAfterSelect") clearAfterSelect = false;

    @Input("resetValue") resetValue: string;

    @Input("showClear") showClear = true;

    @Input("limit") limit = 50;

    @Input("emitFreeText") emitFreeText = false;

    @Input("showDeleteButton") showDeleteButton = false;

    @Input("noPaddingNoMargin") noPaddingNoMargin = false;

    @Input("panelOpen") panelOpen = true;

    @Output("elementSelected") elementSelected = new EventEmitter();

    @Output("deleteSelected") deleteSelected = new EventEmitter();

    options: Observable<any[]>;

    optionSelected = false;

    activatedObject: any;
    activatedObjectSelected = false;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.kieneObject) {
            this.formControl.setValue(this.kieneObject, {emitEvent: false});
        }
        if (this.disabled) {
            this.formControl.disable({emitEvent: false});
        } else {
            this.formControl.enable({emitEvent: false});
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.autocomplete();

            if (this.focus) {
                this.inputAutocomplete?.focus();
            }
            if (this.kieneObject) {
                this.formControl.setValue(this.kieneObject);
                if (this.focus) {
                    this.inputAutocomplete?.focus();
                    if (this.presetSelected) {
                        // this.inputAutocomplete.nativ
                    }
                }
                // this.inputAutocomplete.nativeElement.setSelectionRange(0, this.inputAutocomplete.nativeElement.value.length);
            }

            if (this.required) {
                this.formControl.setValidators(Validators.required);
            }

            if (this.disabled) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }

            if (!this.panelOpen) {
                this.trigger.closePanel();
            }
        }, 1);
    }

    clear() {
        this.formControl.setValue("");
        this.activatedObjectSelected = false;
        this.elementSelected.emit(null);
        this.autocomplete();
    }

    deleteElement(event, option: any) {
        event.stopPropagation();
        this.deleteSelected.emit(option);
    }

    public clearAndFocus() {
        this.formControl.setValue("");
        this.activatedObjectSelected = false;
        this.elementSelected.emit(null);
        this.autocomplete();
        this.inputAutocomplete?.focus();
    }

    public setFocus() {
        this.inputAutocomplete?.focus();
    }

    public setValue(value: any, emit: boolean) {
        this.formControl.setValue(value, {emitEvent: emit});
        this.activatedObjectSelected = false;
    }

    autocomplete() {
        this.options = this.formControl.valueChanges.pipe(
            startWith(""),
            switchMap((value) => {
                if (this.isObject(value)) {
                    value = "";
                }
                this.activatedObjectSelected = false;
                return this.search(value);
            })
        );
    }

    mapper(ko?: any) {
        if (!ko) {
            return "";
        }

        if (typeof ko === "string") {
            return ko;
        }

        if (this.mapperDisplayFields.length > 0) {
            let field = "";
            for (const d of this.mapperDisplayFields) {
                if (ko[d]) {
                    field += ko[d] + " ";
                }
            }
            field = field.trimEnd();
            return field;
        } else if (this.displayFields.length > 0) {
            let field = "";
            for (const d of this.displayFields) {
                if (ko[d]) {
                    field += ko[d] + " ";
                }
            }
            field = field.trimEnd();
            return field;
        }

        return ko[this.displayField];
    }

    emitSelectedElement(event: MatAutocompleteSelectedEvent) {
        const elem = event?.option?.value;
        if (elem) {
            this.optionSelected = true;
        }
        this.elementSelected.emit(elem);
        if (this.clearAfterSelect) {
            this.formControl.setValue(null);
        }
    }

    setActivatedObject(event: MatAutocompleteActivatedEvent) {
        this.activatedObject = event?.option?.value;
        this.activatedObjectSelected = true;
    }

    onBlur() {
        if (this.emitFreeText && !this.optionSelected) {
            const typedValue = this.formControl.value;
            this.elementSelected.emit(typedValue);
            this.optionSelected = false;
        }
        if (this.activatedObjectSelected) {
            this.elementSelected.emit(this.activatedObject);
            this.activatedObjectSelected = false;
        }
    }

    public emitCurrentValue() {
        const typedValue = this.formControl.value;
        if (typedValue) {
            this.elementSelected.emit(typedValue);
            this.optionSelected = false;
            if (this.clearAfterSelect) {
                this.formControl.setValue(null);
            }
        }
    }

    isObject(obj: any): boolean {
        const type = typeof obj;
        return type === "function" || (type === "object" && !!obj);
    }

    getValue(obj: any): string {
        if (this.displayFields.length > 0) {
            let s = "";
            let i = 0;
            for (const df of this.displayFields) {
                let txt;
                if (df.includes(":")) {
                    const split = df.split(":");
                    txt = split[0] + ": " + obj[split[1]];
                } else {
                    txt = obj[df];
                }
                if (txt) {
                    if (i > 0) {
                        s += this.separator;
                    }
                    s += txt;
                    i++;
                }
            }
            return s;
        } else {
            return obj[this.displayField];
        }
    }

    markAsUntouched() {
        this.formControl.markAsUntouched();
    }

    isValid() {
        this.formControl.markAsTouched();
        return this.formControl.valid;
    }

    private search(value: string): Observable<any> {
        let params: HttpParams;
        if (this.params) {
            params = this.params;
            const keys = params.keys();
        } else {
            params = new HttpParams();
        }
        params = params.set("search", value ? value : "");

        if (this.limit) {
            params = params.set("limit", this.limit.toString());
        }

        let url;
        if (this.genericApiUrl) {
            url = this.apiBaseUrl + this.genericApiUrl;
        } else {
            url = this.apiBaseUrl + this.apiUrl + "read.php";
        }

        return this.api.get(url, params).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records),
            catchError((error) => {
                this.messageService.errorMessage(
                    "Es ist ein Fehler aufgetreten!",
                    error
                );
                return of();
            })
        );
    }
}
