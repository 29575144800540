<mat-form-field [ngClass]="noPaddingNoMargin ? 'no-padding-margin-mat-form-field' : ''"
                style="width: 100%;">
    <mat-label>{{placeholder}}</mat-label>
    <input data-cy="kiene-autocomplete" #inputAutocomplete="matInput" matInput [matAutocomplete]="auto"
           [formControl]="formControl" [type]="type" (input)="setCreateObj()"
           [attr.inputmode]="inputmode" [required]="required" (blur)="onBlur()" (keyup.enter)="onBlur()">
    <div matSuffix style="display:flex; align-items: center">
        <mat-progress-spinner *ngIf="showLoadingIndicator && isLoading" color="accent" [diameter]="25"
                              mode="indeterminate">
        </mat-progress-spinner>
        <button *ngIf="showClear" [disabled]="disabled" mat-icon-button color="accent" (click)="clear()">
            <mat-icon>cancel</mat-icon>
        </button>
        <button *ngIf="emitButton" [disabled]="disabled" mat-icon-button color="accent" (click)="emitTypedText()">
            <mat-icon>{{emitButton}}</mat-icon>
        </button>
    </div>


    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="panelWidth" [displayWith]="mapper.bind(this)"
                      (optionSelected)="emitSelectedElement($event)" (optionActivated)="setActivatedObject($event)">
        <mat-option [ngClass]="multiLine ? 'multiLine' : ''" *ngIf="resetValue">{{resetValue}}</mat-option>
        <mat-option [ngClass]="multiLine ? 'multiLine' : ''" *ngFor="let o of options | async" [value]="o">
            <div class="one-row">
                {{getValue(o)}}
                <span class="spacer"></span>
                <div *ngIf="showDeleteButton">
                    <button mat-icon-button (click)="deleteElement($event, o)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </mat-option>
        <mat-option
            style="position: sticky; bottom: 0; background-color: #efefef; border-top: 1px solid #ccc"
            *ngIf="createNewAllowed"
            [value]="''"
            (click)="emitCreateNewObj()"
        >
            <mat-icon>add</mat-icon>
            <span class="neu-erzeugen">{{ createNewText }} (Alt+Enter)</span>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControl.hasError('required')">{{placeholder}} ist ein Pflichtfeld</mat-error>
</mat-form-field>
