import { NgModule } from '@angular/core';
import { AbgabebelegChargeDialogComponent } from './abgabebeleg-charge-dialog/abgabebeleg-charge-dialog.component';
import { AddBetriebsartDialogComponent } from './add-betriebsart-dialog/add-betriebsart-dialog.component';
import { AddClientArtikelDialogComponent } from './add-client-artikel-dialog/add-client-artikel-dialog.component';
import { AddFilesDialogComponent } from './add-files-dialog/add-files-dialog.component';
import {
    ChangeAntibiogrammClientDialogComponent
} from './change-antibiogramm-client-dialog/change-antibiogramm-client-dialog.component';
import {
    ChangeUserEmailAccountDialogComponent
} from './change-user-email-account-dialog/change-user-email-account-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { EditBetriebsartDialogComponent } from './edit-betriebsart-dialog/edit-betriebsart-dialog.component';
import { EditClientArtikelDialogComponent } from './edit-client-artikel-dialog/edit-client-artikel-dialog.component';
import { InvoiceGenerationDialogComponent } from './invoice-generation-dialog/invoice-generation-dialog.component';
import { TextBausteinDialogComponent } from './text-baustein-dialog/text-baustein-dialog.component';
import { TextInputDialogComponent } from './text-input-dialog/text-input-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { JsonDialogComponent } from './json-dialog/json-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { SaveForAllDialogComponent } from './save-for-all-dialog/save-for-all-dialog.component';
import { SelectDateDialogComponent } from './select-date-dialog/select-date-dialog.component';
import { BenutzerrechteDialogComponent } from './benutzerrechte-dialog/benutzerrechte-dialog.component';
import { ConfirmWithOptionDialogComponent } from './confirm-with-option-dialog/confirm-with-option-dialog.component';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { KieneAutocompleteModule } from '../components/kiene-autocomplete/kiene-autocomplete.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { KieneClientAutocompleteModule } from '../components/client-autocomplete/client-autocomlete.module';
import { ComponentsModule } from '../components/components.module';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { SelectMandantDialogComponent } from './select-mandant-dialog/select-mandant-dialog.component';
import { AddTextProposalDialogComponent } from './add-text-proposal-dialog/add-text-proposal-dialog.component';
import { SelectorDialogComponent } from './selector-dialog/selector-dialog.component';
import { SelectorModule } from '../components/selector/selector.module';

@NgModule({
    declarations: [
        AbgabebelegChargeDialogComponent,
        AddBetriebsartDialogComponent,
        AddClientArtikelDialogComponent,
        AddFilesDialogComponent,
        ChangeAntibiogrammClientDialogComponent,
        ChangeUserEmailAccountDialogComponent,
        ConfirmDeleteDialogComponent,
        EditBetriebsartDialogComponent,
        EditClientArtikelDialogComponent,
        InvoiceGenerationDialogComponent,
        TextBausteinDialogComponent,
        TextInputDialogComponent,
        JsonDialogComponent,
        SaveForAllDialogComponent,
        SelectDateDialogComponent,
        BenutzerrechteDialogComponent,
        ConfirmWithOptionDialogComponent,
        FileUploadDialogComponent,
        SelectMandantDialogComponent,
        AddTextProposalDialogComponent,
        SelectorDialogComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        MatBadgeModule,
        MatTreeModule,
        MatTableModule,
        MatListModule,
        MatAutocompleteModule,
        KieneAutocompleteModule,
        KieneClientAutocompleteModule,
        ComponentsModule,
        SelectorModule
    ],
})
export class DialogsModule {
}
