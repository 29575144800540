import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSelectModule} from "@angular/material/select";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {LayoutModule, MediaMatcher} from '@angular/cdk/layout';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule} from '@angular/material/core';
import localeDe from '@angular/common/locales/de';
import {DatePipe, registerLocaleData} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {AuthGuard} from './app.auth.guard';
import {AppRoutingModule} from './app.routing';
import {KieneTableModule} from "./components/kiene-table/kiene-table.module";
import {KieneSecurityInterceptor} from './interceptors/kiene.security.interceptor';
import {AppComponent} from './app.component';
import {LoginService} from './login/login.service';
import {TierartSelectorService} from './components/tierart-selector/tierart-selector.service';
import {TextProposalService} from './laboratory/text-autocomplete/text-proposal.service';
import {LocalStorageService} from '../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

import {MessageService} from './services/message.service';
import {RecPositionService} from './services/rec-position.service';
import {EmailService} from './services/email.service';
import {FileUploadService} from './dialogs/file-upload-dialog/file-upload.service';
import {BetriebsartSelectorService} from './components/betriebsart-selector/betriebsart-selector.service';
import {VerkaufspreisSelectorService} from './components/verkaufspreis-selector/verkaufspreis-selector.service';
import {RabattStaffelService} from './warehouse/material/rabatt-staffel/rabatt-staffel.service';
import {ArticleScanAutocompleteService} from './components/article-scan-autocomplete/article-scan-autocomplete.service';
import {AppService} from './app.service';
import {DropExterneBefundmitteilungDirective} from './laboratory/drop-externe-befundmitteilung.directive';
import {KIENE_DATE_FORMATS, KieneDateAdapter} from './adapters/kiene-date-adapter';
import {FinanzenModule} from './finanzen/finanzen.module';
import {LaboratoryModule} from './laboratory/laboratory.module';
import {WaWiModule} from './wa-wi/wa-wi.module';
import {DocumentsModule} from './documents/documents.module';
import {WarehouseModule} from './warehouse/warehouse.module';
import {StammdatenModule} from './stammdaten/stammdaten.module';
import {AdministrationModule} from './administration/administration.module';
import {DialogsModule} from './dialogs/dialogs.module';
import {VersionInfoModule} from './version-info/version-info.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {HttpCancelService} from './services/http-cancel.service';
import {FortbildungenModule} from './home/fortbildungen/fortbildungen.module';
import {NavigatorDialogModule} from './navigator-dialog/navigator-dialog.module';
import {EntityViewerModule} from './components/entity-viewer/entity-viewer.module';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {KieneMatPaginatorIntl} from './KieneMatPaginatorIntl';
import {MatBadgeModule} from '@angular/material/badge';
import {MatGridListModule} from '@angular/material/grid-list';
import {TabinfoModule} from './components/tabinfo/tabinfo.module';
import {GruppenModule} from './gruppen/gruppen.module';
import {
    API_BASE_URL_SERVICE,
    GIT_VERSIONS_FILE,
    SIGNATURE_URL_SERVICE
} from '../../projects/kiene-core/src/lib/kiene-core.config';
import {environment} from '../environments/environment';
import {KieneErrorsHandler} from './interceptors/kiene.error.interceptor';
import {BenachrichtigungenModule} from '../../projects/kiene-core/src/lib/benachrichtigungen/benachrichtigungen.module';
import {DebugDialogModule} from '../../projects/kiene-core/src/lib/components/debug-dialog/debug-dialog.module';
import {GoToHITButtonModule} from './components/go-to-hitbutton/go-to-hitbutton.module';
import {HomeModule} from './home/home.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ComponentsModule} from './components/components.module';
import {MatMenuModule} from '@angular/material/menu';
import {RegisterModule} from './register/register.module';
import {LoginModule} from './login/login.module';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {versions} from '../environments/versions';
import {DirectivesModule} from '../../projects/kiene-core/src/lib/directives/directives.module';


registerLocaleData(localeDe);

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};

@NgModule({
    declarations: [
        AppComponent,
        DropExterneBefundmitteilungDirective,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LayoutModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        AppRoutingModule,
        FinanzenModule,
        LaboratoryModule,
        WaWiModule,
        DocumentsModule,
        WarehouseModule,
        StammdatenModule,
        AdministrationModule,
        DialogsModule,
        VersionInfoModule,
        FortbildungenModule,
        NavigatorDialogModule,
        EntityViewerModule,
        MatBadgeModule,
        TabinfoModule,
        MatGridListModule,
        GruppenModule,
        BenachrichtigungenModule,
        DebugDialogModule,
        GoToHITButtonModule,
        HomeModule,
        MatProgressBarModule,
        ComponentsModule,
        MatMenuModule,
        RegisterModule,
        LoginModule,
        MatCardModule,
        MatProgressSpinnerModule,
        DirectivesModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        KieneTableModule
    ],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KieneSecurityInterceptor,
            multi: true
        },
        {
            provide: GIT_VERSIONS_FILE,
            useValue: versions,
        },
        AuthGuard,
        LoginService,
        MediaMatcher,
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        {provide: DateAdapter, useClass: KieneDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: KIENE_DATE_FORMATS},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance},
        {provide: API_BASE_URL_SERVICE, useValue: environment.BASE_URI},
        {provide: SIGNATURE_URL_SERVICE, useValue: environment.SIGNATURE_URL},
        {
            provide: MatPaginatorIntl,
            useClass: KieneMatPaginatorIntl
        },
        {
            provide: ErrorHandler,
            useClass: KieneErrorsHandler
        },
        TierartSelectorService,
        TextProposalService,
        LocalStorageService,
        MessageService,
        RecPositionService,
        EmailService,

        FileUploadService,

        BetriebsartSelectorService,
        VerkaufspreisSelectorService,
        RabattStaffelService,
        ArticleScanAutocompleteService,
        AppService,
        DropExterneBefundmitteilungDirective,
        DatePipe,
        HttpCancelService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
