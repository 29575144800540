<h1 mat-dialog-title>Mandant {{ mandant?.bezeichnung }}</h1>
<div mat-dialog-content>
    <mat-tab-group>

        <mat-tab label="Allgemein/Praxis">
            <div style="padding: 12px;">
                <mat-form-field style="width: 100%">
                    <mat-label>Name des Mandanten</mat-label>
                    <input matInput type="text" [formControl]="nameCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Mandantnummer</mat-label>
                    <input matInput type="text" [formControl]="mandantNrCtrl" placeholder="Dreistellige Nummer">
                </mat-form-field>

                <p><strong>Praxisangaben</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>VVVO</mat-label>
                    <input matInput type="number" [formControl]="praxisVVVOCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Praxis/Firmenname</mat-label>
                    <input matInput type="text" [formControl]="praxisNameCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Stra&szlig;e und Nr.</mat-label>
                    <input matInput type="text" [formControl]="praxisStrasseCtrl">
                </mat-form-field>

                <div class="one-row">

                    <mat-form-field style="width: 33%">
                        <mat-label>PLZ</mat-label>
                        <input matInput type="text" [formControl]="praxisPlzCtrl">
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field style="width: 66%">
                        <mat-label>Ort</mat-label>
                        <input matInput type="text" [formControl]="praxisOrtCtrl">
                    </mat-form-field>
                </div>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Adresse</mat-label>
                    <input matInput type="email" [formControl]="praxisEmailCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Telefonnummer</mat-label>
                    <input matInput type="tel" [formControl]="praxisTelefonCtrl">
                </mat-form-field>

            </div>
        </mat-tab>

        <mat-tab label="Finanzen">
            <div style="padding: 12px;">

                <mat-form-field style="width: 100%">
                    <mat-label>USt-ID</mat-label>
                    <input matInput type="text" [formControl]="praxisUstIdCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Steuernummer</mat-label>
                    <input matInput type="text" [formControl]="praxisSteuernummerCtrl">
                </mat-form-field>

                <p><strong>Bankverbindung der Praxis</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>Name der Bank</mat-label>
                    <input matInput type="text" [formControl]="praxisBankNameCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>IBAN</mat-label>
                    <input matInput type="text" [formControl]="praxisBankIbanCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>BIC</mat-label>
                    <input matInput type="text" [formControl]="praxisBankBicCtrl">
                </mat-form-field>

                <p><strong>FiBu</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>Primanota Beraternummer</mat-label>
                    <input matInput type="text" [formControl]="primanotaBeraterNrCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Primanota Mandantennummer</mat-label>
                    <input matInput type="text" [formControl]="primanotaMandantenNrCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Bestellung Extern Konditionen</mat-label>
                    <input matInput type="text" [formControl]="bestellungExternKonditionenCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Bestellung Extern Rechnungsempf&auml;nger</mat-label>
                    <input matInput type="text" [formControl]="bestellungExternRechnungsempfaengerCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Bestellung Extern Lieferbedingungen</mat-label>
                    <input matInput type="text" [formControl]="bestellungExternLieferbedingungenCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Bestellung Extern Versandart</mat-label>
                    <input matInput type="text" [formControl]="bestellungExternVersandartCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Bestellung Extern Ort im Anschreiben</mat-label>
                    <input matInput type="text" [formControl]="bestellungExternOrtCtrl">
                </mat-form-field>
            </div>

        </mat-tab>

        <mat-tab label="E-Mail Kommunikation">
            <div style="padding: 12px;">

                <p><strong>E-Mail Konto Info</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Adresse</mat-label>
                    <input matInput type="email" [formControl]="emailAdresseCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Benutername</mat-label>
                    <input matInput type="text" [formControl]="emailBenutzerCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Passwort</mat-label>
                    <input matInput type="password" [formControl]="emailPasswortCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>SMTP Server</mat-label>
                    <input matInput type="text" [formControl]="emailSmtpCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>SMTP Port</mat-label>
                    <input matInput type="number" [formControl]="emailSmtpPortCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Signatur</mat-label>
                    <textarea rows="8" matInput type="number" [formControl]="emailSignaturCtrl"></textarea>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Untersuchungsauftrag Empf&auml;nger</mat-label>
                    <input matInput type="email" [formControl]="untersuchungsauftragEmailempfaengerCtrl">
                </mat-form-field>

                <p><strong>E-Mail Konto Rechnung</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Adresse</mat-label>
                    <input matInput type="email" [formControl]="rechnung_emailAdresseCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Benutername</mat-label>
                    <input matInput type="text" [formControl]="rechnung_emailBenutzerCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>E-Mail Passwort</mat-label>
                    <input matInput type="password" [formControl]="rechnung_emailPasswortCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>SMTP Server</mat-label>
                    <input matInput type="text" [formControl]="rechnung_emailSmtpCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>SMTP Port</mat-label>
                    <input matInput type="number" [formControl]="rechnung_emailSmtpPortCtrl">
                </mat-form-field>


            </div>
        </mat-tab>

        <mat-tab label="PDF Vorlage">
            <div style="padding: 12px;">

                <p><strong>Akzentfarbe</strong></p>
                <mat-form-field style="width: 100%">
                    <mat-label>Farbe HEX Format</mat-label>
                    <mat-icon matPrefix>tag</mat-icon>
                    <input matInput type="text" [formControl]="pdfFarbeHexCtrl" placeholder="FFFFFF">
                </mat-form-field>
                <div class="one-row">

                    <mat-form-field style="width: 30%">
                        <mat-label>Farbe RGB Rot</mat-label>
                        <input matInput type="number" [formControl]="pdfFarbeRgbRotCtrl" (blur)="updateRgb()">
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field style="width: 30%">
                        <mat-label>Farbe RGB Gr&uuml;n</mat-label>
                        <input matInput type="number" [formControl]="pdfFarbeRgbGruenCtrl" (blur)="updateRgb()">
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field style="width: 30%">
                        <mat-label>Farbe RGB Blau</mat-label>
                        <input matInput type="number" [formControl]="pdfFarbeRgbBlauCtrl" (blur)="updateRgb()">
                    </mat-form-field>
                </div>

                <p><strong>Kopfzeile</strong></p>
                <div *ngIf="!mandant?.mandant_id" class="info-box">
                    <span>Logos k&ouml;nnen erst dann hochgeladen werden, wenn
                    der Mandant initial einmal
                    gespeichert wurde!</span>
                    <span class="spacer"></span>
                </div>
                <div class="one-row" style="margin-bottom: 12px;">
                    <div class="one-column" style="width: 33%;">
                        <!--                        Hoehe auf PDF 27mm bei aufloesung von 72 dpi = 27 * (72 / 25.4) = 76,41-->
                        <img alt="Logo links" [src]="getLogoLinksSource()"
                             style="max-width: 300px; max-height: 150px; margin-bottom: 12px;">
                        <button mat-stroked-button color="accent" (click)="fileSelectorLogoLinks.click()"
                                [disabled]="!mandant?.mandant_id">
                            <mat-icon>upload</mat-icon>
                            Logo Links
                        </button>
                        <input type="file" style="display: none" #fileSelectorLogoLinks
                               (change)="handleFilesLinks($event)"
                               [accept]="'.png, .jpg, .jpeg'">
                    </div>
                    <span class="spacer"></span>
                    <div class="one-column" style="width: 33%;">
                        <img alt="Logo links" [src]="getLogoMitteSource()"
                             style="max-width: 300px; max-height: 150px; margin-bottom: 12px;">
                        <button mat-stroked-button color="accent" (click)="fileSelectorLogoMitte.click()"
                                [disabled]="!mandant?.mandant_id">
                            <mat-icon>upload</mat-icon>
                            Logo Mitte
                        </button>
                        <input type="file" style="display: none" #fileSelectorLogoMitte
                               (change)="handleFilesMitte($event)"
                               [accept]="'.png, .jpg, .jpeg'">
                    </div>
                    <span class="spacer"></span>
                    <div class="one-column" style="width: 33%;">
                        <img alt="Logo links" [src]="getLogoRechtsSource()"
                             style="max-width: 300px; max-height: 150px; margin-bottom: 12px;">
                        <button mat-stroked-button color="accent" (click)="fileSelectorLogoRechts.click()"
                                [disabled]="!mandant?.mandant_id">
                            <mat-icon>upload</mat-icon>
                            Logo Rechts
                        </button>
                        <input type="file" style="display: none" #fileSelectorLogoRechts
                               (change)="handleFilesRechts($event)"
                               [accept]="'.png, .jpg, .jpeg'">
                    </div>
                </div>

                <mat-form-field style="width: 100%">
                    <mat-label>Text Absender &uuml;ber Adressfeld</mat-label>
                    <input matInput type="text" [formControl]="pdfAbsenderCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Ortsangabe Labor</mat-label>
                    <input matInput type="text" [formControl]="ortLaborCtrl">
                </mat-form-field>

                <mat-checkbox [formControl]="rechnungTierarztAngebenCtrl">Behandelnden Tierarzt in Rechnungsposition
                    angeben
                </mat-checkbox>

                <p><strong>Fu&szlig;zeile</strong></p>

                <mat-form-field style="width: 100%">
                    <mat-label>Schriftgr&ouml;&szlig;e in pt</mat-label>
                    <input matInput type="number" [formControl]="pdfFooterFontSizeCtrl">
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Spalte 1</mat-label>
                    <textarea rows="4" matInput type="number" [formControl]="pdfFooterSpalte1Ctrl"></textarea>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Spalte 2</mat-label>
                    <textarea rows="4" matInput type="number" [formControl]="pdfFooterSpalte2Ctrl"></textarea>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Spalte 3</mat-label>
                    <textarea rows="4" matInput type="number" [formControl]="pdfFooterSpalte3Ctrl"></textarea>
                </mat-form-field>

                <mat-form-field style="width: 100%">
                    <mat-label>Spalte 4</mat-label>
                    <textarea rows="4" matInput type="number" [formControl]="pdfFooterSpalte4Ctrl"></textarea>
                </mat-form-field>

            </div>
        </mat-tab>

    </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!isMandantValid() || isLoading">Speichern
    </button>
</div>
