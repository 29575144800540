import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {LocalStorageService} from '../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneSession} from '../../projects/kiene-core/src/lib/services/local-storage/kiene-session';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.isLoggedIn()) {
            // console.debug(state.url);
            if (state.url.startsWith('/belege') || state.url.startsWith('/gruppen') || state.url.startsWith('/logistik') ||
                state.url.startsWith('/gruppen-schweine') || state.url.startsWith('/schlachthoefe') || state.url.startsWith('/viehhaendler')) {
                this.localStorageService.setCurrentClientVisible(
                    true,
                    'AuthGuard'
                );
            } else {
                this.localStorageService.setCurrentClientVisible(
                    false,
                    'AuthGuard'
                );
            }

            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return true;
    }

    isLoggedIn() {
        const cu: KieneSession = this.localStorageService.getCurrentUser();
        if (!cu) {
            return false;
        }
        // more then one mandant
        if (cu.mandanten && cu.mandanten.length > 1) {
            // check if one mandant is selected
            if (!this.localStorageService.getCurrentMandant()) {
                return false;
            }
        }
        // logged in so return true
        return true;
    }
}
