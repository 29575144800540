import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { Subject, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { MessageService } from "./services/message.service";
import { DebugDialogComponent } from "../../projects/kiene-core/src/lib/components/debug-dialog/debug-dialog.component";

@Injectable({
    providedIn: "root",
})
export class AppService {
    private dataLoading = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog) {
        this.monitorCurrentRoute();
    }

    public monitorCurrentRoute() {
        this.route.url.subscribe((urlSegments: UrlSegment[]) => {
            urlSegments.forEach((segment) => {
                console.debug(segment.path);
            });
        });
    }

    setDataLoading(loading: boolean) {
        this.dataLoading.next(loading);
    }

    watchDataLoading(): Observable<boolean> {
        return this.dataLoading.asObservable();
    }

    openDebugDialog() {
        this.dialog.open(DebugDialogComponent);
    }
}
