import {HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {MassnahmenplanRind} from './massnahmenplan-rind';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {forkJoin, of, Subject, Subscription} from 'rxjs';
import {MessageService} from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TabinfoService} from '../../../components/tabinfo/tabinfo.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';

@Injectable({
    providedIn: 'root',
})
export class MassnahmenplanRindService {

    tabInfoNeuladenSubject = new Subject<boolean>();
    private ladeAlleErstelltenSubscription: Subscription;

    constructor(private pdf: PdfViewerDesktopService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService,
                private dialog: MatDialog,
                private tabInfoService: TabinfoService,
                private tableService: KieneTableService,
                private localStorageService: LocalStorageService,
                private api: KieneBackendApiService) {
    }

    openAnlageA(
        kunde_id: number,
        jahr: number,
        halbjahr: number,
        tierart_id: number,
        abgabebelege: boolean,
        befundeantibiogramme: boolean,
        bestandsbesuchsprotokolle: boolean
    ) {
        const url = 'massnahmenplan_rind/create_anlage_a.php';
        let params = new HttpParams();
        params = params.set('kunde_id', kunde_id?.toString());
        params = params.set('jahr', jahr?.toString());
        params = params.set('halbjahr', halbjahr?.toString());
        params = params.set('tierart_id', tierart_id);
        params = params.set(
            'befundeantibiogramme',
            befundeantibiogramme ? '1' : '0'
        );
        params = params.set('abgabebelege', abgabebelege ? '1' : '0');
        params = params.set(
            'bestandsbesuchsprotokolle',
            bestandsbesuchsprotokolle ? '1' : '0'
        );

        this.pdf.openPdfViewer(
            new PdfViewerDialogInput(url, params, 'Anlagen')
        );
    }

    showPdf(mpr: MassnahmenplanRind) {
        const titel =
            'Massnahmenplan Rind ' +
            mpr.kunde_nr_intern?.toString() +
            ' ' +
            mpr.vvvo +
            ' ' +
            mpr._0_jahr +
            '-' +
            mpr._0_halbjahr;

        const input = new PdfViewerDialogInput(
            'massnahmenplan_rind/read_or_mail_pdf.php',
            new HttpParams().set('mnp_rind_id', mpr?.mnp_rind_id?.toString()),
            titel,
            {
                email: {
                    sende_mail: true,
                    kunde_id: mpr?.kunde_id,
                },
                targetRoute: ['/belege', 'massnahmenplaene'],
            }
        );
        this.pdf.openPdfViewer(input);
    }

    watchTabInfoNeuladen() {
        return this.tabInfoNeuladenSubject.asObservable();
    }

    alleOffenenAbschliessen() {
        const inp = new ConfirmDialogInput();
        inp.headline = 'Alle abschließen?';
        inp.text = 'Wollen Sie wirklich alle Maßnahmenpläne abschließen?';
        inp.okButton = 'abschließen';
        this.dialog.open(ConfirmDialogComponent, {data: inp}).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.ok && result?.confirmed) {
                    this.ladeAlleErstelltenSubscription?.unsubscribe();
                    this.ladeAlleErstelltenSubscription = this.ladeAlleZumAbschliessen().subscribe({
                        next: response => {
                            this.alleAbschliessen(response.records).subscribe({
                                next: abschliessenResponse => {
                                    this.messageService.infoMessage('Alle erfolgreich abgeschlossen!');
                                    this.tabInfoService.setReload('massnahmenplan_rind/tabinfo.php');
                                    this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');

                                },
                                error: err => {
                                    this.messageService.errorMessage('Fehler!', err);
                                    this.tabInfoService.setReload('massnahmenplan_rind/tabinfo.php');
                                    this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');
                                }
                            });
                        }
                    });
                }
            }
        });

    }

    public ladeAlleZumUnterschreiben() {
        const kunde_id = this.localStorageService.getCurrentClient()?.kunde_id;
        let params = new HttpParams();
        if (kunde_id) {
            params = params.set('kunde_id', kunde_id);
        }
        params = params.set('unterschrift_kunde', 0);
        params = params.set('status_id', 62);
        return this.api.getAll(this.apiBaseUrl + 'massnahmenplan_rind/read.php', params);
    }

    public dokumenteUnterschreiben(plaene: MassnahmenplanRind[]) {
        for (const plan of plaene) {
            this.dokumentUnterschreiben(plan);
        }
    }

    public dokumentUnterschreiben(mnp: MassnahmenplanRind) {
        this.api.post(this.apiBaseUrl + 'massnahmenplan_rind/update_unterschrift.php', mnp).subscribe({
            next: response => {
                this.messageService.infoMessage('Ihre Unterschrift wurde erfolgreich im Dokument eingefügt!');
                this.tableService.reload();
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    public alleAbschliessen(plaene: MassnahmenplanRind[]) {
        const arr = [];
        for (const plan of plaene) {
            arr.push(this.abschliessen(plan));
        }
        return forkJoin(arr);
    }

    public abschliessen(mpr: MassnahmenplanRind) {
        mpr.status_id = 62;
        if (!mpr.tierarzt_id) {
            mpr.tierarzt_id = this.localStorageService.getCurrentUser().tierarzt_id;
        }
        return this.api.updateGeneric(this.apiBaseUrl + 'massnahmenplan_rind/update.php', mpr);
    }

    ladeAlleZumAbschliessen() {
        if (this.localStorageService.getCurrentClient()) {
            const kunde_id = this.localStorageService.getCurrentClient()?.kunde_id;
            let params = new HttpParams();
            params = params.set('kunde_id', kunde_id);
            params = params.set('status_id', 61);
            params = params.set('abschliessbar', 1);

            return this.api.getAll(this.apiBaseUrl + 'massnahmenplan_rind/read.php', params);
        } else {
            return of();
        }
    }
}
