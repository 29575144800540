import {ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import {NavigationEnd, Router} from '@angular/router';
import {AuthGuard} from './app.auth.guard';
import {Title} from '@angular/platform-browser';
import {AppService} from './app.service';
import {AutoUploadDialogComponent} from './documents/dokumente/auto-upload-dialog/auto-upload-dialog.component';
import {ChangeCurrentClientService} from './administration/client/change-current-client/change-current-client.service';
import {MatSidenav, MatSidenavContainer} from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import {EntityViewerService} from './components/entity-viewer/entity-viewer.service';
import {NavigatorDialogComponent} from './navigator-dialog/navigator-dialog/navigator-dialog.component';
import {versions} from '../environments/versions';
import {KieneNetworkLogEvent, NetworkService} from './services/network.service';
import {JsonDialogComponent} from './dialogs/json-dialog/json-dialog.component';
import {KieneTableService} from './components/kiene-table/kiene-table.service';
import {BenutzerrechteDialogComponent} from './dialogs/benutzerrechte-dialog/benutzerrechte-dialog.component';
import {
    BenachrichtigungenService
} from '../../projects/kiene-core/src/lib/benachrichtigungen/benachrichtigungen.service';
import {LocalStorageService} from '../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    BenachrichtigungClickEvent
} from '../../projects/kiene-core/src/lib/benachrichtigungen/classes/benachrichtigung-click-event';
import {Subscription} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../projects/kiene-core/src/lib/kiene-core.config';
import {environment} from '../environments/environment';
import {KieneSession} from '../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {MessageService} from '../../projects/kiene-core/src/lib/services/message-service/message.service';
import {Client} from '../../projects/kiene-core/src/lib/components/client/classes/client';
import {UserService} from '../../projects/kiene-core/src/lib/services/user/user.service';
import {
    BetriebAuswaehlenDialogComponent
} from '../../projects/kiene-core/src/lib/components/dialogs/betrieb-auswaehlen-dialog/betrieb-auswaehlen-dialog.component';
import {Mandant} from '../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {'(window:keydown)': 'checkForKeyShortcut($event)'},
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
    title = 'app';
    subTitle = environment.SUB_TITLE;
    colorName = 'primary';
    // infos for current version
    appVersion: string = versions.version;
    commitHash: string = versions.revision;
    branch: string = versions.branch;

    @ViewChild('snav') sidenav: MatSidenav;
    @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

    isDataLoading = false;
    showToolbar = true;

    mobileQuery: MediaQueryList;

    isMobileScreen = false;
    currentClient: Client = new Client();
    currentMandant: Mandant = new Mandant();
    currentUserName = '';
    kieneSession: KieneSession;
    showCurrentClient = false;
    showShoppingCart = false;
    showInventur = false;
    numberOfShoppingCartItems = '0';
    networkLogEvents: KieneNetworkLogEvent[] = [];
    routerEventsSubscription: Subscription;
    showNetworkLog = false;
    isCurrentUserKunde = false;
    isCurrentUserSgs = false;
    isCurrentUserViehhaendler = false;
    protected readonly environment = environment;
    private _mobileQueryListener: () => void;

    constructor(
        private titleService: Title,
        private currentClientService: ChangeCurrentClientService,
        private router: Router,
        private authGuard: AuthGuard,
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private userService: UserService,
        private appService: AppService,
        private breakpointObserver: BreakpointObserver,
        private entityViewerService: EntityViewerService,
        private networkLogEvent: NetworkService,
        private kieneTableService: KieneTableService,
        private benachrichtigungenService: BenachrichtigungenService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.breakpointObserver
            .observe(Breakpoints.Handset)
            .subscribe((response) => {
                this.isMobileScreen = response.matches;
            });

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.routerEventsSubscription = this.router.events.subscribe({
            next: (event) => {
                if (event instanceof NavigationEnd) {
                    this.router.navigated = false;
                    if (this.router.url === '/') {
                        console.log('keine route');
                        const routeStartseite = this.localStorageService.getStartseite();
                        if (routeStartseite && routeStartseite.length > 1) {
                            this.router.navigate([routeStartseite]).then();
                        }
                    }
                }

                if (this.router.url.startsWith('/sign')) {
                    this.showToolbar = false;
                }

            },
        });
    }

    toggleEntityViewer() {
        this.entityViewerService.toggleVisible();
    }

    setSession(session: KieneSession) {
        this.kieneSession = session;
        if (this.kieneSession) {
            this.currentUserName = this.kieneSession.vorname + ' ' + this.kieneSession.nachname;
            this.isCurrentUserKunde = this.localStorageService.isCurrentUserKunde();
            this.isCurrentUserSgs = this.localStorageService.isCurrentUserSgs();
            this.isCurrentUserViehhaendler = this.localStorageService.isCurrentUserViehhaendler();
            if (this.kieneSession.passwort_aendern > 0) {
                this.userService.neuesPasswortVergeben(this.kieneSession.benutzer_id).subscribe({
                    next: (result: boolean) => {
                        if (result === true) {
                            this.kieneSession.passwort_aendern = 0;
                            this.localStorageService.setCurrentUser(this.kieneSession);
                        }
                    }
                });
            }
        } else {
            this.currentUserName = undefined;
            this.isCurrentUserKunde = false;
            this.isCurrentUserSgs = false;
            this.isCurrentUserViehhaendler = false;
        }
    }

    istCurrentUserKundeOderViehhaendler() {
        return this.isCurrentUserKunde || this.isCurrentUserViehhaendler;
    }

    ngOnInit() {
        this.setSession(this.localStorageService.getCurrentUser());
        this.colorName = environment.production ? 'primary' : 'primary';
        let title = 'VETVET. ' + this.appVersion;
        if (!environment.production) {
            title = 'TEST ' + title;
        }
        this.titleService.setTitle(
            title
        );
        this.currentMandant = this.localStorageService.getCurrentMandant();
        this.currentClient = this.localStorageService.getCurrentClient();

        this.localStorageService.watchLoggedIn().subscribe((loggedIn) => {
            this.showToolbar = loggedIn;
        });

        this.localStorageService
            .watchCurrentUser()
            .subscribe((data: KieneSession) => {
                this.setSession(data);
            });

        this.appService.watchDataLoading().subscribe((dataLoading) => {
            this.isDataLoading = dataLoading;
        });

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            this.currentClient = client;
        });

        this.localStorageService.watchShowCurrentClient().subscribe((show) => {
            this.showCurrentClient = show;
        });

        this.localStorageService.watchShowShoppingCart().subscribe((show) => {
            this.showShoppingCart = show;
        });

        this.localStorageService
            .watchNumberOfShoppingCartItems()
            .subscribe((items) => {
                this.numberOfShoppingCartItems = items.toString();
            });

        this.localStorageService
            .watchChangeCurrentMandant()
            .subscribe((mandant) => {
                this.currentMandant = mandant;

                // hier wurde die falsche API genutzt!!
                if (this.localStorageService.currentUserHasPermission(4431)) {
                    this.kieneTableService
                        .get(
                            `${this.apiBaseUrl}inventur_eintrag/read_aktiv.php`
                        )
                        .subscribe((kieneApi) => {
                            if (kieneApi.records?.length > 0) {
                                this.showInventur = true;
                            }
                        });
                }
            });

        this.networkLogEvent.watchEvent().subscribe((event) => {
            this.networkLogEvents.unshift(event);
        });

        this.benachrichtigungenService
            .watchBenachrichtigungClickEvent()
            .subscribe({
                next: (event) => {
                    this.handleBenachrichtigungClickEvent(event);
                },
            });
    }

    currentUserIsVet() {
        return this.localStorageService.getCurrentUser().tierarzt_id;
    }

    showMyVetStock() {
        this.router.navigate(['/startseite/meinbestand']);
    }

    closeSidenav() {
        this.sidenav.close();
    }

    isMenuVisible(menu: string): boolean {
        return this.localStorageService.currentUserCanSeeMenu(menu);
    }

    ngOnChanges() {
    }

    isLoggedIn() {
        if (this.authGuard.isLoggedIn()) {
            return true;
        }
        return false;
    }

    isCurrentUserPhitoUser(): boolean {
        return this.localStorageService.isCurrentUserPhitoUser();
    }

    isSidenavOpen() {
        if (!this.isLoggedIn()) {
            return false;
        } else {
            if (this.mobileQuery.matches) {
                return false;
            } else {
                return true;
            }
        }
    }

    sessionRefresh() {
        this.kieneTableService.post(this.apiBaseUrl + 'session/refresh.php', undefined).subscribe({
            next: (session: KieneSession) => {
                this.localStorageService.setCurrentUser(session);
                this.messageService.infoMessage('Session wurde erfolgreich aktualisiert!');
                location.reload();
            },
            error: err => {
                this.messageService.errorMessage('Session konnte nicht aktualisiert werden!', err);
            }
        });
    }

    getSidenavMode() {
        if (!this.isLoggedIn()) {
            return 'over';
        } else {
            if (this.mobileQuery.matches) {
                return 'over';
            } else {
                return 'side';
            }
        }
    }

    public logout() {
        this.router.navigate(['/login']);
    }

    ngOnDestroy(): void {
        this.routerEventsSubscription.unsubscribe();
    }

    checkForKeyShortcut(event: KeyboardEvent) {
        if (event.key === 'F2') {
            if (this.sidenav.opened) {
                this.sidenav.close();
            } else {
                this.sidenav.open();
            }
        }
        if (event.altKey && event.code === 'KeyB') {
            this.openChangeClientDialog();
            // this.openBetriebAuswaehlenDialog();
        } else if (event.altKey && event.code === 'KeyM') {
            this.openSelectMandantDialog();
        } else if (event.altKey && event.ctrlKey && event.code === 'KeyP') {
            this.appService.openDebugDialog();
        } else if (event.altKey && event.code === 'KeyP') {
            this.openNavigatorDialog();
        } else if (event.altKey && event.code === 'KeyA') {
            this.openNewAbgabebeleg();
        } else if (event.altKey && event.code === 'KeyR') {
            this.openBenutzerrechteDialog();
        } else if (event.altKey && event.code === 'KeyL') {
            this.logout();
        } else if (event.altKey && event.code === 'KeyN') {
            this.showNetworkLog = !this.showNetworkLog;
        }
        if (event.altKey && event.code === 'Digit1') {
            this.router.navigate([this.getLinkForFirstTab('startseite')]);
        }
        if (event.altKey && event.code === 'Digit2') {
            this.router.navigate([this.getLinkForFirstTab('stammdaten', 'betriebe')]);
        }
        if (event.altKey && event.code === 'Digit3') {
            this.router.navigate([this.getLinkForFirstTab('lager')]);
        }
        if (event.altKey && event.code === 'Digit4') {
            this.router.navigate([this.getLinkForFirstTab('wawi')]);
        }
        if (event.altKey && event.code === 'Digit5') {
            this.router.navigate([
                this.getLinkForFirstTab('belege', 'abgabebelege'),
            ]);
        }
        if (event.altKey && event.code === 'Digit6') {
            this.router.navigate([this.getLinkForFirstTab('labor')]);
        }
        if (event.altKey && event.code === 'Digit7') {
            this.router.navigate([this.getLinkForFirstTab('finanzen')]);
        }
        if (event.altKey && event.code === 'Digit8') {
            this.router.navigate([this.getLinkForFirstTab('administration')]);
        }
        if (event.altKey && event.code === 'Digit9') {
            this.router.navigate([this.getLinkForFirstTab('reporte')]);
        }
        if (event.altKey && event.code === 'Digit0') {
            this.router.navigate([this.getLinkForFirstTab('gruppen')]);
        }
        if (event.altKey && event.code === 'Minus') {
            // ß Taste
            this.router.navigate([this.getLinkForFirstTab('logistik')]);
        }
        /*if(event.code === 'Escape'){ // macht nur Ärger
            window.history.back();
        }*/
        if (event.altKey && event.code === 'Escape') {
            window.history.forward();
        }
        if (event.altKey) {
            console.log('key', event.code);
        }
    }

    openNewAbgabebeleg() {
        this.router.navigate(['/belege/abgabebelege/neu']);
    }

    openNavigatorDialog() {
        this.dialog.open(NavigatorDialogComponent, {
            minWidth: '400px',
            width: '50%',
        });
    }

    openBenutzerrechteDialog() {
        this.dialog.open(BenutzerrechteDialogComponent);
    }

    setCurrentClient(client: Client) {
        this.currentClient = client;
        this.localStorageService.setCurrentClient(client);
    }

    getLinkForFirstTab(nameOfNavItem: string, presetValue?: string): string {
        const session: KieneSession = this.localStorageService.getCurrentUser();

        if (session == null) {
            return '';
        }

        const tabs: string[] = session.menue[nameOfNavItem];
        if (tabs && tabs.length > 0) {
            // benutzer mit der rolle 'Verwaltung' sollen bei wawi zuerst den Tab Bestellung-Extern sehen
            // 28.04.2023: Aktuell hat Verwaltung gar kein Recht mehr fuer Bestellung-Extern.
            if (nameOfNavItem === 'wawi' && this.localStorageService.isCurrentUserVerwaltung()) {
                // return '/wawi/bestellung-extern';
                return '/wawi/bestellung-intern';
            }
            return (
                '/' +
                nameOfNavItem +
                '/' +
                (presetValue && tabs.includes(presetValue)
                    ? presetValue
                    : tabs[0])
            );
        }

        return '';
    }

    openChangeClientDialog() {
        this.currentClientService.openChangeDialog('APP Comp');
    }

    openBetriebAuswaehlenDialog() {
        const dialogRef = this.dialog.open(BetriebAuswaehlenDialogComponent, {
            minWidth: '90vw',
            position: {top: '10px'}
        });
        dialogRef.afterClosed().subscribe({
            next: (client: Client) => {
                if (client) {
                    this.localStorageService.setCurrentClient(client);

                }
            }
        });
    }

    openSelectMandantDialog() {
        this.currentClientService.openChangeMandantDialog();
    }

    showWarenkorb() {
        this.router.navigate(['/wawi/warenkorb/']);
    }

    openUploadDocumentDialog() {
        const dialogRef = this.dialog.open(AutoUploadDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                apiUrl: 'dokument/create_auto.php',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
            }
        });
    }

    showHttpBody(nle: KieneNetworkLogEvent) {
        this.dialog.open(JsonDialogComponent, {
            data: {
                text: nle.network.body,
            },
        });
    }

    goToInventur() {
        this.router.navigate(['/lager/inventur/']).then();
    }

    alert(event: KieneNetworkLogEvent) {
        const dialogRef = this.dialog.open(JsonDialogComponent, {
            data: {
                text: event?.network.body
            }
        });
    }


    private handleBenachrichtigungClickEvent(
        event: BenachrichtigungClickEvent
    ) {
        console.log(
            'handleBenachrichtigungClickEvent: %s',
            JSON.stringify(event)
        );
        if (event) {
            const benachrichtigung = event.benachrichtigung;
            if (benachrichtigung.gruppe_id) {
                if (benachrichtigung.gt_id) {
                    this.router
                        .navigate(
                            [
                                '/gruppen/aktuelle',
                                benachrichtigung.gruppe_id.toString(),
                            ],
                            {
                                queryParams: {
                                    search: benachrichtigung.ohrmarke_notnull.slice(
                                        -10
                                    ),
                                },
                            }
                        )
                        .then();
                } else {
                    this.router
                        .navigate(
                            [
                                '/gruppen/aktuelle',
                                benachrichtigung.gruppe_id.toString(),
                            ],
                            {
                                queryParams: {
                                    search: ' ',
                                },
                            }
                        )
                        .then();
                }
            } else if (benachrichtigung.tierliste_id) {
                this.router.navigate(['/gruppen/tierlisten']).then();
            } else if (benachrichtigung.buchungs_id) {
                if (this.localStorageService.currentKieneSession.benutzerrollen.find(e => e.benutzerrolle_id == 20)) {
                    this.router.navigate(['viehhaendler/anfrage-details/', benachrichtigung.buchungs_id.toString()]).then();
                } else if (this.localStorageService.currentKieneSession.benutzerrollen.find(e => e.benutzerrolle_id == 22)) {
                    this.router.navigate(['schlachthoefe/anfrage-details/', benachrichtigung.buchungs_id.toString()]).then();
                } else {
                    this.router.navigate(['gruppen/anfrage-details/', benachrichtigung.buchungs_id.toString()]).then();
                }
            }
        }
    }
}

