import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from "@angular/material/chips";
import {KieneClientAutocompleteModule} from "../components/client-autocomplete/client-autocomlete.module";
import { GruppeComponent } from './gruppe/gruppe.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { KieneTableModule } from '../components/kiene-table/kiene-table.module';
import { AktuelleGruppenComponent } from './aktuelle-gruppen/aktuelle-gruppen.component';
import { GruppenRoutingModule } from './gruppen-routing.module';
import { GruppenComponent } from './gruppen/gruppen.component';
import { GruppenverwaltungModule } from '../documents/gruppenverwaltung/gruppenverwaltung.module';
import { GruppeDetailsComponent } from './gruppe-details/gruppe-details.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { KieneAutocompleteModule } from '../components/kiene-autocomplete/kiene-autocomplete.module';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import {
    AddGruppeTierReklamationDialogComponent
} from './add-gruppe-tier-reklamation-dialog/add-gruppe-tier-reklamation-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TiereAlsCSVUploadDialogComponent } from './tiere-als-csvupload-dialog/tiere-als-csvupload-dialog.component';
import { HistorieGruppenComponent } from './historie-gruppen/historie-gruppen.component';
import {
    KieneSelectorModule
} from '../../../projects/kiene-core/src/lib/components/kiene-selector/kiene-selector.module';
import { MatDividerModule } from '@angular/material/divider';
import { OhrmarkeModule } from '../../../projects/kiene-core/src/lib/ohrmarke/ohrmarke.module';
import { AbteilDialogComponent } from './abteil-dialog/abteil-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { DeleteButtonModule } from '../components/delete-button/delete-button.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GruppeTierTodMeldenComponent } from './gruppe-tier-tod-melden/gruppe-tier-tod-melden.component';
import { TierEditierenDialogComponent } from './tier-editieren-dialog/tier-editieren-dialog.component';
import { ZugangMeldenDialogComponent } from './zugang-melden-dialog/zugang-melden-dialog.component';
import {
    EntsorgerKundenNrNachtragenComponent
} from './gruppe-tier-tod-melden/entsorger-kunden-nr-nachtragen/entsorger-kunden-nr-nachtragen.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GruppeInformationenDialogComponent } from './gruppe-informationen-dialog/gruppe-informationen-dialog.component';
import { GlobaleTiersucheComponent } from './gruppen/globale-tiersuche/globale-tiersuche.component';
import {
    GruppenModule as GruppenModuleCore
} from '../../../projects/kiene-core/src/lib/components/gruppen/gruppen.module';
import {
    GruppeEinstallenDialogComponent
} from './gruppe-details/gruppe-einstallen-dialog/gruppe-einstallen-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { GruppeTierModule } from '../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier.module';
import {
    GruppeBemerkungenVerwaltenDialogComponent
} from './gruppe-bemerkungen-verwalten-dialog/gruppe-bemerkungen-verwalten-dialog.component';
import { GruppenDashboardModule } from './gruppen-dashboard/gruppen-dashboard.module';
import { TabinfoModule } from '../components/tabinfo/tabinfo.module';
import { HITMeldungenComponent } from './hitmeldungen/hitmeldungen.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GewichtePreiseComponent } from './gruppe-details/gewichte-preise/gewichte-preise.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DirectivesModule } from '../../../projects/kiene-core/src/lib/directives/directives.module';
import { AbhollisteFilterDialogComponent } from './gruppen/globale-tiersuche/abholliste-filter-dialog/abholliste-filter-dialog.component';
import { AktuelleGruppenSchweineComponent } from './schweine/aktuelle-gruppen-schweine/aktuelle-gruppen-schweine.component';
import { ZeitslotBuchenComponent } from './schweine/zeitslot-buchen/zeitslot-buchen.component';
import { ZeitslotHistorieComponent } from './schweine/zeitslot-historie/zeitslot-historie.component';
import { ZeitslotBuchungAendernComponent } from './schweine/zeitslot-buchung-aendern/zeitslot-buchung-aendern.component';
import { AnfrageDetailsComponent } from './schweine/anfrage-details/anfrage-details.component';
import { GruppeSchweineDetailsComponent } from './schweine/gruppe-schweine-details/gruppe-schweine-details.component';
import { GewichtePreiseSchweineComponent } from './schweine/gruppe-schweine-details/gewichte-preise-schweine/gewichte-preise-schweine.component';
import { DetailsVerlaufStatisikenComponent } from './schweine/gruppe-schweine-details/details-verlauf-statisiken/details-verlauf-statisiken.component';
import { MatListModule } from '@angular/material/list';
import { AnfrageDetailsModule } from '../components/anfrage-details/anfrage-details.module';
import { AddTierToListeDialogComponent } from './gruppen/globale-tiersuche/add-tier-to-liste-dialog/add-tier-to-liste-dialog.component';
import { AbhollisteNumberInputDialogComponent } from './gruppen/globale-tiersuche/abhol-liste-number-input-dialog/abholliste-number-input-dialog.component';
import { AbhollisteDialogComponent } from './gruppen/globale-tiersuche/add-tier-to-liste-dialog/abholliste-dialog/abholliste-dialog.component';
import { AbhollistenComponent } from './abhollisten/abhollisten.component';
import { AbhollisteDetailsComponent } from './abhollisten/abholliste-details/abholliste-details.component';

@NgModule({
    declarations: [
        GruppeComponent,
        AktuelleGruppenComponent,
        GruppenComponent,
        GruppeDetailsComponent,
        AddGruppeTierReklamationDialogComponent,
        HistorieGruppenComponent,
        AbteilDialogComponent,
        GruppeTierTodMeldenComponent,
        TierEditierenDialogComponent,
        ZugangMeldenDialogComponent,
        TiereAlsCSVUploadDialogComponent,
        EntsorgerKundenNrNachtragenComponent,
        GruppeInformationenDialogComponent,
        GlobaleTiersucheComponent,
        GruppeEinstallenDialogComponent,
        GruppeBemerkungenVerwaltenDialogComponent,
        HITMeldungenComponent,
        GewichtePreiseComponent,
        //schweine
        AktuelleGruppenSchweineComponent,
        ZeitslotBuchenComponent,
        ZeitslotHistorieComponent,
        ZeitslotBuchungAendernComponent,
        AnfrageDetailsComponent,
        GruppeSchweineDetailsComponent,
        GewichtePreiseSchweineComponent,
        DetailsVerlaufStatisikenComponent,
        AbhollisteFilterDialogComponent,
        AddTierToListeDialogComponent,
        AbhollisteNumberInputDialogComponent,
        AbhollisteDialogComponent,
        AbhollistenComponent,
        AbhollisteDetailsComponent,
    ],
    exports: [GruppeTierTodMeldenComponent, GruppenComponent],
    imports: [
        CommonModule,
        GruppenRoutingModule,
        MatTabsModule,
        MatIconModule,
        KieneTableModule,
        MatTabsModule,
        GruppenverwaltungModule,
        MatCardModule,
        MatDialogModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatRadioModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        MatCheckboxModule,
        KieneAutocompleteModule,
        KieneSelectorModule,
        MatDividerModule,
        OhrmarkeModule,
        MatMenuModule,
        DeleteButtonModule,
        MatAutocompleteModule,
        MatTooltipModule,
        GruppenModuleCore,
        MatExpansionModule,
        GruppeTierModule,
        GruppenDashboardModule,
        TabinfoModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        DirectivesModule,
        MatListModule,
        AnfrageDetailsModule,
        MatChipsModule,
        KieneClientAutocompleteModule,
    ],
})
export class GruppenModule {
}
