import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from '../services/message.service';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {versions} from '../../environments/versions';
import {KieneNetworkLogEvent, NetworkService} from '../services/network.service';

@Injectable()
export class KieneSecurityInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private messageService: MessageService,
        private networkService: NetworkService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const requestTimestamp = new Date();
        const requestMethod = request.method;
        const currentHeader = request.headers.get('Phito-Type');
        let authRequest: HttpRequest<any> = null;
        if (currentHeader === 'fileupload') {
            authRequest = request.clone({
                setHeaders: this.getSecurityHeaders(request.headers, false),
            });

        } else {
            authRequest = request.clone({
                setHeaders: this.getSecurityHeaders(request.headers, true),
            });
        }


        return next.handle(authRequest).pipe(
            catchError((response: HttpErrorResponse) => {
                this.networkService.addEvent(
                    new KieneNetworkLogEvent(
                        response.status,
                        response.url,
                        requestMethod,
                        response.error,
                        null,
                        requestTimestamp
                    )
                );
                if (response.status === 401) {
                    console.log('401 mandantid: ' + this.localStorageService.getCurrentMandant()?.mandant_id);
                    this.messageService.errorMessage(
                        'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
                        response
                    );
                    this.router.navigate(['/login']).then();
                } else if (response.status === 403) {
                    this.messageService.errorMessage(
                        'Sie haben leider keine Berechtigung für diese Aktion.',
                        response
                    );
                }
                // else {
                //     console.log('ergebnis: %s', JSON.stringify(response));
                //     const ke = new KieneError();
                //     ke.name = response.name;
                //     ke.message = response.message;
                //
                //     this.api.handleException(
                //         environment.production,
                //         ke,
                //         this.apiBaseUrl
                //     );
                // }

                return throwError(() => response);
            }),
            tap((res: HttpEvent<HttpEventType>) => {
                if (res instanceof HttpResponse) {
                    this.networkService.addEvent(
                        new KieneNetworkLogEvent(
                            res.status,
                            res.url,
                            requestMethod,
                            JSON.stringify(res.body, null, 2),
                            null,
                            requestTimestamp
                        )
                    );

                    const uiVersion = res.headers?.get('UI-Version');
                    if (uiVersion) {
                        if (versions && versions.version !== uiVersion) {
                            this.messageService.updateMessage(uiVersion);
                        }
                    }
                }
            })
        );
    }

    private getSecurityHeaders(
        currentHeaders: HttpHeaders,
        withContentType: boolean
    ): any {
        const kieneSession = this.localStorageService.getSession();

        const kieneCurrentMandant = this.localStorageService.getCurrentMandant();
        let currentMandantId = -1;

        if (kieneCurrentMandant) {
            currentMandantId = kieneCurrentMandant.mandant_id;
        }
        let token = '0';
        if (kieneSession) {
            token = kieneSession.token;
            if (kieneSession.admin) {
                const debugToken = this.localStorageService.getDebugToken();
                if (debugToken) {
                    token = debugToken;
                }
            }
        }

        const headers = {
            'X-XSRF-TOKEN': token.toString(),
            'MANDANTID': currentMandantId.toString()
        };

        if (!currentHeaders.has('Content-Type') && withContentType) {
            headers['Content-Type'] = 'application/json';
        }

        return headers;
    }
}
