<h1 mat-dialog-title>
    <div class="one-row">
        <mat-icon *ngIf="data.isWarning" color="warn" style="margin-right: 12px; transform: scale(1.4)">warning
        </mat-icon>
        <span>{{ data.headline }}</span>
        <span class="spacer"></span>

    </div>
</h1>
<div mat-dialog-content>
    <p style="white-space: pre-wrap;">{{ data.text }}</p>
</div>
<div mat-dialog-actions align="end">
    <button *ngIf="data?.showCancel" data-cy="confirm-dialog-abort" mat-button (click)="cancel()">{{
            data?.cancelButton
        }}
    </button>
    <button data-cy="confirm-dialog-confirm" mat-flat-button [color]="data?.buttonColor" (click)="ok()">
        {{ data.okButton }}
    </button>
</div>
